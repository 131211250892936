import { runDelay } from 'public/src/js/utils/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export const analysis =  {
  // 点击sort
  clickSort (payload) {
    daEventCenter.triggerNotice({
      daId: '2-17-7',
      extraData: {
        ...payload
      }
    })
  },
  // 点击分类
  clickCate ({ index, catId, isSide, parentId, facet, gfilter, isCancel, clickType }) {
    daEventCenter.triggerNotice({
      daId: '2-17-10',
      extraData: {
        index,
        catId,
        facet,
        gfilter,
        isSide,
        parentId,
        isCancel,
        clickType
      }
    })
  },
  // 点击属性
  /**
   * 
   * @param {{
    "selectedAttrs": "87_474-87_755-87_568-87_417",
    "index": 1,
    "curSelectedItem": {
        "id": 87,
        "mix_value": "87_417",
        "label": "M",
        "active": true
    }
}} param0 
   */
  clickAttr ({ selectedAttrs, clickType, curSelectedItem, facet, gfilter }) {
    daEventCenter.triggerNotice({
      daId: '2-17-1',
      extraData: {
        attrId: curSelectedItem.attr_id,
        selectedAttrs,
        clickType: clickType, // 下拉筛选top*和侧边弹出筛选filter
        facet,
        gfilter,
        curSelectedItem,
        // nodeType: curSelectedItem.nodeType,
      }
    })
  },
  // 点击tsps
  clickTsps (extraData = {}) {
    daEventCenter.triggerNotice({
      daId: '2-17-20',
      extraData,
    })
  },
  // 点击Brand
  clickBrand (extraData = {}) {
    daEventCenter.triggerNotice({
      daId: '2-17-20',
      extraData,
    })
  },
  // 点击标签云
  clickCloudTag ({ tagId, tagCate, tagIndex, facet, gfilter, isPicNavCate, isCancel, clickType }) {
    daEventCenter.triggerNotice({
      daId: '2-17-4',
      extraData: {
        tagId,
        tagCate,
        tagIndex,
        facet,
        gfilter,
        isCancel,
        isPicNavCate,
        clickType
      }
    })
  },
  // 曝光标签云
  exposeCloudTag ({ cloudTags, clickType }) {
    if (!cloudTags?.length) return

    daEventCenter.triggerNotice({
      daId: '2-17-5',
      delay: 2000,
      extraData: {
        cloudTags,
        clickType
      }
    })
  },
  // 点击标签云展开按钮
  // clickLabelMore () {
  //   daEventCenter.triggerNotice({
  //     daId: '2-17-13',
  //   })
  // },
  // 拖拽价格
  dragPriceRange ({ startMinPrice, startMaxPrice, facet, facet2, gfilter, clickType, priceInput }) {
    daEventCenter.triggerNotice({
      daId: '2-17-3',
      extraData: {
        minPrice: startMinPrice,
        maxPrice: startMaxPrice,
        facet,
        facet2,
        gfilter,
        clickType,
        priceInput
      }
    })
  },
  // 点击中间两个坑位的bar
  clickTopTwoFilter ({ index, filterType, text }) {
    daEventCenter.triggerNotice({
      daId: '2-17-9',
      extraData: {
        index,
        filterType,
        attribute_type: text
      }
    })
  },
  // 点击展开侧边筛选
  openSideFilter () {
    daEventCenter.triggerNotice({
      daId: '2-17-8',
    })
  },
  // 侧边筛选点击重置
  sideClickReset () {
    daEventCenter.triggerNotice({
      daId: '2-17-2',
    })   
  },
  // 点击daily date
  clickDate ({ date, index, clickType }) {
    daEventCenter.triggerNotice({
      daId: '2-17-15',
      extraData: {
        date,
        index,
        clickType
      }
    }) 
  },
  // filter曝光上报
  exposeFilter ({ filterAttrs, filterPrices }) {
    const hasAttrs = filterAttrs && Array.isArray(filterAttrs) && filterAttrs.length
    const { min_price, max_price } = filterPrices || {}
    let attrsList = []
    if (hasAttrs) {
      filterAttrs.forEach(item => {
        const isAttrGroup = item.show_group
        let itemAcc = isAttrGroup ? item.groups || item.data || [] : item.attr_values || item.data || []
        
        itemAcc = itemAcc.reduce((acc, attr) => {     
          acc.push(attr.attr_filter || attr.mix_value)
          return acc
        }, [])

        attrsList = [...attrsList, ...itemAcc]
      })
      
      daEventCenter.triggerNotice({
        daId: '2-17-16',
        delay: 2000,
        extraData: {
          attributeList: attrsList.join('-'),
          priceRange: `${+min_price || '-'}\`${+max_price || '-'}`
        }
      })
    }
  },
  exposeCategory () {
    daEventCenter.triggerNotice({
      daId: '1-5-1-20',
      delay: 2000,
    })
  },

  clickCategory () {
    daEventCenter.triggerNotice({ daId: '1-5-1-20' })
  },
  
  // 图文导航点击上报
  clickPicTopNav(data) {
    daEventCenter.triggerNotice({
      daId: '2-17-19',
      extraData: {
        page: 'productList',
        ...data
      }
    })
  },

  // 排序入口显示
  exposeSortEntrance() {
    daEventCenter.triggerNotice({
      daId: '2-17-23',
      delay: 2000,
    })

  },

  // 排序入口点击
  clickSortEntrance() {
    daEventCenter.triggerNotice({
      daId: '2-17-24'
    })
  },

  exposeSort(extraData) {
    daEventCenter.triggerNotice({
      daId: '2-17-26',
      delay: 2000,
      extraData,
    })
  },
  exposeKidsCollection (extraData) { 
    daEventCenter.triggerNotice({
      daId: '2-17-28',
      extraData,
    })
  },
  clickKidsCollection (extraData) { 
    daEventCenter.triggerNotice({
      daId: '2-17-29',
      extraData,
    })
  },
  clickKidsCollectionBoardAdd (extraData) { 
    daEventCenter.triggerNotice({
      daId: '2-17-31',
      extraData,
    })
  },
  clickKidsCollectionBoardKids (extraData) { 
    daEventCenter.triggerNotice({
      daId: '2-17-32',
      extraData,
    })
  },
  exposeKidsCollectionEntranceSelected () {
    runDelay(() => {
      daEventCenter.triggerNotice({
        daId: '2-17-33',
      })
    })
  },
  exposeKidsCollectionEntranceSelectedNull () {
    runDelay(() => {
      daEventCenter.triggerNotice({
        daId: '2-17-34',
      }) 
    }) 
  }
}

export const handleClickBarAnalysis = (topTwoData, payload) => {
  const { topOne, topTwo } = topTwoData
  const { filterType, activeIndex, text } = payload

  if (filterType == 'date' || filterType == 'category') {
    analysis.clickTopTwoFilter({
      filterType,
      index: activeIndex
    }) 
  } else if (filterType == 'attribute') {
    let filterType = ''
    if (activeIndex == 1) {
      filterType = topOne.data.id
    } else if (activeIndex == 2) {
      filterType = topTwo.data.id
    }
    analysis.clickTopTwoFilter({
      text,
      filterType,
      index: activeIndex
    })
  } else if (filterType == 'sideFilter') {
    analysis.openSideFilter()
  } else if (filterType === 'sort') {
    analysis.clickSortEntrance()
  }
}
