import { formatMuitlData } from 'public/src/pages/components/filter_new/common/format'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { TAG_ITEM_TYPE, FILTER_BAR_MAP, FILTER_RESULT_TYPE_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'
import { getSourceInsideFilterOrCloudTagPanel } from './index.js'
import { safeSplit } from '../utils/index.js'

// 是否自定义属性
export function getIsCustomAttr (attr, key = 'data') { 
  return attr?.[key]?.some(item => item[key]?.length > 0)
}

// 获取当前选中的属性
export function getActiveAttrList (attrs, queryParams, filterBar, queryParamsKey = 'attr_ids') {
  let { attr_ids, tsp_ids = '' } = queryParams
  attr_ids = queryParams[queryParamsKey] || ''
  const { isCccxFilterConfig } = filterBar?.filterConfig || {}
  if (!isCccxFilterConfig && (!attr_ids || !attr_ids.split)) return []

  if (isCccxFilterConfig) {
    return [
      ...safeSplit(queryParams?.attr_node_ids)
    ]
  }
  let activeAttrList = isCccxFilterConfig ? tsp_ids.split(',')?.map(id => id.split('_')[1]) : []

  attrs.forEach(attr => {
    if (/^-*$/g.test(attr_ids)) return

    // 将mix_value长度从大到小排序, 避免匹配到错误的值
    let list = [...(attr.data || [])].sort((a, b) => (b.mix_value.length - a.mix_value.length)) 

    list.forEach(item => {
      const { mix_value } = item
      if (!(new RegExp(mix_value)).test(attr_ids)) return

      attr_ids = attr_ids.replace(mix_value, '')
      activeAttrList.push(mix_value)
    })
  })

  return activeAttrList
}

export function formatAttrList (attrs, filterBar) {
  if (attrs && !attrs.length) return []

  // 只保留有数据的项
  attrs.forEach(attr => {
    if (attr.show_group) {
      attr.data = attr.groups
      attr.dataType = 'img'
      return
    } 
    attr.dataType = 'mulit'
    attr.data = attr.attr_values
    if (getIsCustomAttr(attr, 'attr_values')) { 
      attr.data = formatAttrList(attr.data, filterBar)
      // 处理dataType
      attr.data.forEach(item => {
        item.data.forEach(obj => {
          if (obj.nodeType == 7) {
            obj.dataType = 'tsp'
            obj.label_img = obj.attr_image
          } else if (obj.attr_image) {
            obj.dataType = 'img'
            obj.label_img = obj.attr_image
          }
        })
      })
    }
  })

  const { isCccxFilterConfig } = filterBar?.filterConfig || {}
  return formatMuitlData({
    attr_id: 'id',
    attr_name: 'name',
    data: 'data',
    dataItem: {
      attr_id: 'id',
      attr_value: 'label',
      group_name: 'label',
      group_image: 'label_img',
      [isCccxFilterConfig ? 'nodeId' : 'attr_filter']: 'mix_value',
    }
  }, attrs, false)
}

// 去除属性类型的图文
function filterByPicTopNav (attrs, picTopNav = []) {
  if (picTopNav.length < 2) return attrs

  const attrIds = picTopNav.reduce((ids, item) => {
    item.tabType == 2 && ids.push(item.tabId)

    return ids
  }, [])

  return attrs.filter(item => !attrIds.includes(item.attr_id))
}

// 
export function filterAttrsByNodeType (attrs = []) { 
  return attrs?.filter(attr => attr.nodeType == 1) || []
}

export function getAttrList ({ filterBar, picTopNav, queryParams, filterBarType, queryParamsKey, showNum = true }) {
  if (!filterBar?.filterData?.filterAttrs?.length) return []
  
  const { isCccxFilterConfig } = filterBar?.filterConfig || {}
  let list = filterBar?.filterData?.filterAttrs ?? []
  list = isCccxFilterConfig ? filterAttrsByNodeType(list) : list
  list = filterByPicTopNav(list, picTopNav)
  list = formatAttrList(list, filterBar)
  const activeAttrList  = getActiveAttrList(list, queryParams, filterBar, queryParamsKey)
  return list.map(attr => {
    const selectedIds = [] // 被选中的属性值个数
    attr.data.forEach(item => {
      if (isCccxFilterConfig) {
        if (activeAttrList?.includes(item.nodeId)) {
          item.active = true
          selectedIds.push(item.nodeId)
        }
      }else if (activeAttrList?.includes(item.mix_value)) {
        item.active = true
        selectedIds.push(item.mix_value)
      }
    })
    const selectedNum = selectedIds.length
    let label = (selectedNum && showNum) ? `${attr.name}(${selectedNum})` : attr.name
    if (filterBarType == FILTER_BAR_MAP.CLOUD_TAGS) { 
      const config = (filterBar?.filterConfig?.tagCloudSlotConfig || []).find(item => item.tagId == attr.id)
      const labelConfig = config?.displayContent?.labelLang || config?.tagName  || ''
      labelConfig && (label = (selectedNum && showNum) ? `${labelConfig}(${selectedNum})` : labelConfig)
    }

    return {
      ...attr,
      value: attr.id,
      label,
      selectedIds,
      selectedNum,
      active: selectedNum > 0,
      tagItemType: TAG_ITEM_TYPE.ATTR
    }
  })
}

// 处理 标签 属性选中
export function handleParamsByTagAttrs({ data, clickType, curSelectedItem, operation, fhContext, googleContext, isCccxFilterConfig, sourceFrom }) {
  let selectedAttrsWithHotMark = [] // 带有hot标识符
  const attrIds = []
  const attrNodeIds = []
  const attrValues = []
  const selectAttributeGroup = []
  const filterResult = {
    type: FILTER_RESULT_TYPE_MAP.ATTR,
    isDelete: !curSelectedItem.active,
    label_id: isCccxFilterConfig ? curSelectedItem?.nodeId : curSelectedItem?.attr_filter,
    label: curSelectedItem.label,
    img: curSelectedItem.label_img,
    attr_filter: curSelectedItem?.attr_filter,
    nodeId: curSelectedItem?.nodeId,
  }

  data.forEach(item => {
    if (isCccxFilterConfig && item.nodeType != 2 && item.nodeType != 3) return
    if (item.nodeType == 3) selectAttributeGroup.push(item.nodeId)
    const attrs = item.mix_value.split('-')
    selectedAttrsWithHotMark = selectedAttrsWithHotMark.concat(attrs)
    attrIds.push(item.attr_filter)
    attrNodeIds.push(item.nodeId)
    attrValues.push(item.label.replace('-', '')) // one-size 等特殊情况去掉中横线
  })

  // 埋点
  if (operation != 'reset') {
    analysis.clickAttr({
      selectedAttrs: selectedAttrsWithHotMark.join('-'),
      clickType,
      curSelectedItem,
      facet: fhContext?.[`attr${curSelectedItem.id}`],
      gfilter: googleContext?.gfilter,
    })
  }
  const params = { 
    attr_ids: attrIds.join('-'),
    attr_values: attrValues.join('-'),
    ...getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active: curSelectedItem.active })
  }

  filterResult.ids = isCccxFilterConfig ? attrNodeIds : attrIds

  if (isCccxFilterConfig) {
    params.attr_node_ids = attrNodeIds.join('-'),
    params.selectAttributeGroup = selectAttributeGroup.join('-')
  }
  
  return { params, filterResult }
}

// 处理 侧边栏 属性选中
export function handleParamsBySideFilter({ data, index, clickType, curSelectedItem, operation, fhContext, googleContext, isCccxFilterConfig, sourceFrom }) {
  let selectedAttrsWithHotMark = [] // 带有hot标识符
  const attrIds = []
  const attrNodeIds = []
  const attrValues = []
  const tspIds = []
  const brandIds = []
  const selectAttributeGroup = []
  const isTsp = curSelectedItem?.nodeType == 7
  const isBrand = curSelectedItem?.nodeType == 9
  let label_id = curSelectedItem?.attr_filter 

  const filterResult = {
    label_id,
    img: curSelectedItem.label_img,
    type: FILTER_RESULT_TYPE_MAP.ATTR,
    isDelete: !curSelectedItem.active,
    attr_filter: curSelectedItem?.attr_filter,
    nodeId: curSelectedItem?.nodeId,
    label: curSelectedItem?.attr_value || curSelectedItem?.group_name,
  }

  data.forEach(item => {
    if (item.nodeType == 3) {
      selectAttributeGroup.push(item.nodeId)
    } else if (item.nodeType == 7) {
      label_id = `${item.id}_${item.mix_value}`
      !tspIds.includes(label_id) && tspIds.push(label_id)
      return
    } else if (item.nodeType == 9) {
      label_id = item.nodeId
      !brandIds.includes(item.nodeId) && brandIds.push(item.nodeId)
      return
    }

    const attrs = item.attr_filter.split('-')
    selectedAttrsWithHotMark = selectedAttrsWithHotMark.concat(attrs)
    !attrIds.includes(item.attr_filter) && attrIds.push(item.attr_filter)
    !attrNodeIds.includes(item.nodeId) && attrNodeIds.push(item.nodeId)
    attrValues.push(item.label.replace('-', '')) // one-size 等特殊情况去掉中横线
  })

  // 埋点
  if (operation != 'reset') {
    curSelectedItem.nodeType == 7
      ? analysis.clickTsps({
        clickType,
        tsps: data.filter(item => item.id == curSelectedItem.id).map(item => `${item.id}_${item.mix_value}`),
        curSelectedItem,
      })
      : analysis.clickAttr({
        selectedAttrs: selectedAttrsWithHotMark.join('-'),
        index,
        clickType,
        curSelectedItem,
        facet: fhContext?.[`attr${curSelectedItem.id}`],
        gfilter: googleContext?.gfilter,
      })
  }
  const params = { 
    attr_ids: attrIds.join('-'),
    attr_values: attrValues.join('-'),
    selectAttributeGroup: selectAttributeGroup.join('-'),
    ...getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active: curSelectedItem.active })
  }
  if (isTsp) {
    filterResult.label_id = label_id
    filterResult.type = FILTER_RESULT_TYPE_MAP.TSP
    filterResult.ids = tspIds
  } else if (isBrand) {
    filterResult.label_id = label_id
    filterResult.type = FILTER_RESULT_TYPE_MAP.BRAND
    filterResult.ids = brandIds
  } else if (isCccxFilterConfig) {
    filterResult.ids = attrNodeIds
    filterResult.label_id = curSelectedItem?.nodeId
  } else {
    filterResult.ids = attrIds
    filterResult.label_id = label_id
  }

  if (isCccxFilterConfig) {
    params.tsp_ids = tspIds.join(',')
    params.attr_node_ids = attrNodeIds.join('-')
    params.brand_ids = brandIds.join(',')
  }
  
  return { params, filterResult }
}

/**
 * format java返回数据结构
 * // TODO liao 自定义属性 待确定
 * java返回数据结构
 * {
    "attr_name": "Size",
    "attr_value_id": "474",
    "show_group": "0",
    "attr_value": "one-size",
    "attr_id": "87"
  }

---- 转为 ----
{
    "attr_id": 87
    "attr_name": "Size",
    "attr_name_en": "Size",
    "show_group": 0,
    "attr_values": [
        {
            "attr_value_en": "one-size",
            "attr_value_id": 474,
            "goods_num": 5,
            "attr_value": "one-size",
            "attr_id": 87
        }
    ]
}

 *  */ 
export function formatJavaAttribute (attribute) {
  const map = {}
  attribute.forEach(item => { 
    const { attr_id } = item
    if (attr_id < 0) return 
    const obj = map[attr_id]
      || {
        attr_id,
        show_group: Number(item.show_group) || 0,
        attr_name: item.attr_name,
        attr_name_en: item.attr_name,
        attr_values: []
      }
    item.attr_filter = `${item.attr_id}_${item.attr_value_id}`
    obj.attr_values.push(item)
    map[attr_id] = obj
  })

  return Object.values(map)
}

