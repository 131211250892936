
// #region 通用相关常量
export const HEADER_HEIGHT = typeof window === 'undefined' ? 44 : Math.floor(1.17 * 37.5 / 375 * document.body.clientWidth) // 头部高度

// 筛选初始化参数
export const RESTET_QUERY_PARAMS = {
  child_cat_id: '',
  attr_ids: '',
  attr_node_ids: '',
  attr_values: '',
  min_price: '',
  max_price: '',
  tsp_ids: '',
  tag_ids: '',
  mall_tag: '',
  pic_cate_id: '',
  choosed_ids: '',
  showAllCategory: '',
  choose_ids: '',
  selectAttributeGroup: '',
  last_parent_cat_id: '',
  brand_ids: '',
  source: '',
  source_status: '',
}

export const SCROLL_DIRECTION = {
  UP: 'up', // 上滑
  DOWN: 'down' // 下滑
}
// #endregion

// #region FilterBar相关常量
export const FILTER_BAR_MAP = {
  PIC_TOP_NAV: 'picTopNav', 
  NAV_BAR: 'navBar',
  CLOUD_TAGS: 'cloudTags'
}
// #endregion

// #region 图文相关常量
export const PIC_TOP_NAV_LAYOUT = {
  PICKS: 'picks', // picks列表
  BLANK: 'blank', // 点击跳转【pageId=1222771895】
  LIST: 'list', // 单列表
  TABS: 'tabs', // 通用多tabs
  CARD: 'card', // 卡片
}

export const PIC_TOP_NAV_SHAPE = {
  RECT: 'rect',
  CIRCLE: 'circle'
}

// #endregion

// #region navbar相关常量
export const NAV_BAR_TYPE = {
  SORT_EXPORT: 'sortExport', // 排序外露
  SORT_QUICK: 'sortQuick', // 排序快捷
  ATTR_EXPORT: 'attrExport', // 属性外露
}

// navbar one/two位置数据类型
export const NAV_BAR_ONE_TWO_TYPE = {
  BUTTON: 'button', // 按钮[most popular]
  PRICE: 'price', // 价格
  ATTR: 'attr', // 属性
}

// #endregion

// #region 标签相关常量
// 标签布局
export const TAG_LAYOUT = {
  SWIPER: 'swiper', // swiper滑动
  TILED: 'tiled', // 平铺 【券列表】
}

// 所有标签类型
export const TAG_ITEM_TYPE = {
  CLOUD_TAG: 'cloudTag',
  PRICES: 'prices',
  DAILY: 'daily',
  CATEGORY: 'category',
  PIC_NAV_CATE: 'picNavCate',
  TSP: 'tsp',
  ATTR: 'attr',
  KIDS: 'kids',
  BRAND: 'brand',  // 品牌筛选
  CUSTOM: 'custom', // 自定义筛选组
}

// cloudCloud标签类型
export const TAG_CLOUD_TYPE = {
  MALL: 'mall', // 本地/跨境mall
  QUICK_SHIP: 'quickShip', // 快速发货
  SALE: 'sale', // 促销
  BLACK_FRIDAY: 'blackFriday', // 黑五
  OTHER: 'other', // 其他[new...]
}
// #endregion

export const FILTER_RESULT_TYPE_MAP = {
  TSP: 'tsp', // tsp
  KIDS: 'kids', // kids
  PRICE: 'price', // 价格
  BRAND: 'brand', // 品牌
  DAILY: 'daily', // daily
  ATTR: 'attr', // 属性
  CLOUD_TAGS: 'cloudTags', // 云标签
  CATEGORY: 'category', // category
  PIC_TOP_NAV: 'picTopNav', // 图文导航
  CUSTOM: 'custom', // 自定义[tagCustom标签点击clear]
}

